<template>
  <!-- Page Content -->
  <div class="scroll">
    <!-- Search Content -->
    <input
      type="search"
      v-model="query"
      placeholder="Search..."
      class="w-full p-2 mb-3 mt-3"
    />
    <!-- Leads Favourites Content -->
        <!-- Leads Favourites Table Content -->
        <table
          class="min-w-max w-full mt-5 table-auto paginated"
          style="padding: 10px"
          :sticky-header="true"
          :data="filteredUsers"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :loading="loading"
          :mobile-cards="true"
        >
          <!-- Leads Favourites Table Content Header -->
          <thead>
          <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
            <th
              @click="sort('reminderDate')"
              class="heading-text"
              :class="{ sorting: currentSort === 'reminderDate' }"
            >
              <Reminder />
              <MenuDown
                v-if="isSorted('reminderDate', 'desc')"
                class="inline-block absolute"
              />
              <MenuUp
                v-if="isSorted('reminderDate', 'asc')"
                class="inline-block absolute"
              />
            </th>
            <th
              @click="sort('firstName')"
              class="heading-text"
              :class="{ sorting: currentSort === 'firstName' }"
            >
              Name
              <MenuDown
                v-if="isSorted('firstName', 'desc')"
                class="inline-block absolute"
              />
              <MenuUp
                v-if="isSorted('firstName', 'asc')"
                class="inline-block absolute"
              />
            </th>
            <th
              @click="sort('email')"
              class="heading-text tableColumnWidth"
              :class="{ sorting: currentSort === 'email' }"
            >
              Email
              <MenuDown
                v-if="isSorted('email', 'desc')"
                class="inline-block absolute"
              />
              <MenuUp
                v-if="isSorted('email', 'asc')"
                class="inline-block absolute"
              />
            </th>
            <th
              @click="sort('phone')"
              class="heading-text"
              :class="{ sorting: currentSort === 'phone' }"
            >
              Phone
              <MenuDown
                v-if="isSorted('phone', 'desc')"
                class="inline-block absolute"
              />
              <MenuUp
                v-if="isSorted('phone', 'asc')"
                class="inline-block absolute"
              />
            </th>
            <th
              @click="sort('rating')"
              class="heading-text"
              :class="{ sorting: currentSort === 'rating' }"
            >
              Rating
              <MenuDown
                v-if="isSorted('rating', 'desc')"
                class="inline-block absolute"
              />
              <MenuUp
                v-if="isSorted('rating', 'asc')"
                class="inline-block absolute"
              />
            </th>
            <th
              @click="sort('agentId')"
              class="heading-text"
              :class="{ sorting: currentSort === 'agentId' }"
            >
              Agent
              <MenuDown
                v-if="isSorted('agentId', 'desc')"
                class="inline-block absolute"
              />
              <MenuUp
                v-if="isSorted('agentId', 'asc')"
                class="inline-block absolute"
              />
            </th>
            <th
              @click="sort('created')"
              class="heading-text"
              :class="{ sorting: currentSort === 'created' }"
            >
              Added Date
              <MenuDown
                v-if="isSorted('created', 'desc')"
                class="inline-block absolute"
              />
              <MenuUp
                v-if="isSorted('created', 'asc')"
                class="inline-block absolute"
              />
            </th>
            <th
                @click="sort('modified')"
                class="heading-text"
                :class="{ sorting: currentSort === 'modified' }"
              >
                Modified Date
                <MenuDown
                  v-if="isSorted('modified', 'desc')"
                  class="inline-block absolute"
                />
                <MenuUp
                  v-if="isSorted('modified', 'asc')"
                  class="inline-block absolute"
                />
              </th>
          </tr>
          </thead>

          <!-- Leads Favourites Table Content Body-->
          <tbody
            v-if="this.filteredUsers"
            class="text-gray-600 text-sm font-light"
          >
          <tr
            v-for="user in sortedUsers"
            :key="user.id"
            class="border-b border-gray-200 hover:bg-gray-100"
          >
            <td
              class="py-3 px-3 text-center"
            >
              {{ user.dateReminderDate }}
            </td>
            <td
              @click="showDetail(user)"
              class="py-3 px-3 text-center cursor-pointer"
            >
              {{ user.firstName }} {{ user.lastName }}
            </td>
            <td
              class="py-3 px-3 text-center tableColumnWidth"
            >
              <a :href="`mailto:${user.email}`">
                {{ user.email }}
              </a>
            </td>
            <td
              class="py-3 px-6 text-center"
            >
              <a :href="`tel:${user.phone}`">
                {{ user.phone }}
              </a>
            </td>
            <td
              class="py-3 px-3 text-center cursor-pointer"
            >
              <a
                class="py-1 px-1 text-center rounded-full"
                :class="getRatingClass(user.rating)"
              >
                {{ user.rating }}
              </a>
            </td>
            <td
              class="py-3 text-center"
            >
              <span v-if="user.agentId">
                {{ getAgent(user.agentId) }}
              </span>
            </td>
            <td
              class="py-3 px-3 text-center"
            >
              {{ user.created.seconds| moment("from", "now") }}
            </td>
            <td
                class="py-3 px-3 text-center"
              >
                {{ user.dateModified }}
              </td>
          </tr>
          </tbody>
        </table>

        <!-- Leads Favourites Table Content Pagination -->
        <p class="pagination-container">
          <button
            v-if="currentPage != 1"
            @click="prevPage"
            class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
          >
            &lt; Prev
          </button>

          <button
            v-if="(this.currentPage*this.pageSize) < this.filteredUsers.length"
            @click="nextPage"
            class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
          >
            Next &gt;
          </button>
        </p>

        <!-- Leads Favourites Sidebar Menu Detail Content -->
        <LeadDetail
          :show="showProfile"
          @close="showProfile = false"
          @mark-favourite="markFavourite"
          :favourited="favourited"
        />

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
import {leadRemindersCollection, leadsCollection, profilesCollection} from "../../firebase.js";
import isNil from "lodash/isNil";
import "@mdi/font/css/materialdesignicons.min.css";
import LeadDetail from "@/components/admin/LeadDetail.vue";
import permissionsMixin from "@/mixins/permissions";
import MenuDown from "vue-material-design-icons/MenuDown";
import MenuUp from "vue-material-design-icons/MenuUp";
import Reminder from "vue-material-design-icons/BellRingOutline";
import {APP_ADMIN_PAGINATION} from "@/Constants";

export default {
  name: "AdminLeadTableFavouritesList",
  mixins: [permissionsMixin],
  components: {
    LeadDetail,
    MenuDown,
    MenuUp,
    Reminder,
  },
  data: () => ({
    currentSort:'reminderDate',
    currentSortDir:'desc',
    pageSize: APP_ADMIN_PAGINATION,
    currentPage:1,
    users: [],
    agents: [],
    reminders: [],
    loading: true,
    query: "",
    isAdmin: false,
    showProfile: true,
    activeTab: "favourites",
    now: new Date(),
  }),
  methods: {
    isSorted(field, direction) {
      if (this.currentSort === field && this.currentSortDir === direction) {
        return true;
      }
      return false;
    },
    sort:function(s) {
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='desc'?'asc':'desc';
      }
      this.currentSort = s;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredUsers.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    toggleTabs: function (tabValue) {
      this.activeTab = tabValue;
    },
    showDetail(lead) {
      this.showProfile = true;
      this.$store.commit("SET_LEAD", lead);
    },
    refreshLead() {
      leadsCollection
        .doc(this.lead.id)
        .get()
        .then((doc) => this.$store.commit("SET_LEAD", this.setUser(doc)));
    },
    getAgent(agentId) {
      if (this.agents.length > 0) {
        let agent = this.agents.find((item) => item.id === agentId)

        if (agent) {
          return `${agent.firstName} ${agent.lastName}`;
        }

        return `Not Registered Agents`;
      }
      return "";
    },
    markFavourite(leadId) {
      if (this.favourited) {
        this.lead.favourited = this.lead.favourited.filter(
          (item) => item !== this.user.data.uid
        );
        leadsCollection.doc(leadId).update({
          favourited: firebase.firestore.FieldValue.arrayRemove(
            this.user.data.uid
          ),
        });
      } else {
        if (!this.lead.favourited) {
          this.lead.favourited = [];
        }
        this.lead.favourited.push(this.user.data.uid);
        leadsCollection.doc(leadId).update({
          favourited: firebase.firestore.FieldValue.arrayUnion(
            this.user.data.uid
          ),
        });
      }
    },
    getRatingClass(rating) {
      if (rating) {
        return `rating-${rating.toLowerCase()}`;
      }
    },
    setUser(doc) {
     let dateReminderDate = '-';
     let reminderDate = '';
     if (doc.data().reminderDate) {
       const firestoreDate = firebase.firestore.Timestamp.fromDate(doc.data().reminderDate.toDate())
       dateReminderDate = firestoreDate.toDate().toLocaleDateString()
       reminderDate = firestoreDate.toDate()
     }

      let dateModified = '-';
      let modified = '';
      if (doc.data().modified) {
        const firestoreDate = firebase.firestore.Timestamp.fromDate(doc.data().modified.toDate())
        dateModified = firestoreDate.toDate().toLocaleDateString()
        modified = firestoreDate.toDate()
      }
      return {
        id: doc.id,
        firstName: doc.data().firstName,
        lastName: doc.data().lastName,
        email: doc.data().email,
        phone: doc.data().phone,
        rating: doc.data().rating,
        agentId: doc.data().agentId,
        created: doc.data().created,
        modified: modified,
        dateModified: dateModified,
        location: doc.data().location,
        source: doc.data().source,
        interestedin: doc.data().interestedin,
        userId: doc.data().userId,
        favourited: doc.data().favourited,
        note: doc.data().note,
        contacthistory: doc.data().contacthistory || null,
        dateReminderDate: dateReminderDate,
        reminderDate: reminderDate,

        group: doc.data().group || null,
        market: doc.data().market || null,
        purchase: doc.data().purchase || null,
        radio: doc.data().radio || null,
      };

    },
    setUsers(snapshot) {
      let users = [];
      snapshot.forEach((doc) => {
        users.push(this.setUser(doc));
      });
      users.sort((a, b) => {
        return b.created.seconds - a.created.seconds;
      });
      this.users = users;
      this.loading = false;
    },
    getUsers() {
      profilesCollection
        .where("email", "==", this.user.data.email)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            const role = doc.data().role;
            const adminRoles = ["admin", "agent-principal", "superadmin"];
            this.isAdmin = adminRoles.includes(role);
            if (this.isAdmin) {
              leadsCollection.onSnapshot((snap) => {
                this.setUsers(snap);
              });
            } else {
              leadsCollection
                .where("agentId", "==", this.user.data.uid)
                .onSnapshot((snap) => {
                  this.setUsers(snap);
                });
            }
          });
        });
    },
    getReminders() {
      leadRemindersCollection
        .orderBy("date", "desc")
        .get()
        .then((snap) => {
          let reminders = [];

          snap.forEach((doc) => {
            let reminderDate = '';
            if (doc.data().date) {
              const firestoreDate = firebase.firestore.Timestamp.fromDate(doc.data().date.toDate())
              reminderDate = firestoreDate.toDate().toLocaleDateString()

            }
            reminders.push({
              id: doc.id,
              date: doc.data().date,
              text: doc.data().text,
              completed: doc.data().completed || false,
              triggered: doc.data().triggered || false,
              reminderDate: reminderDate
            });
            if (doc.data().date <= this.now) {
              if (doc.data().completed !== true) {
                this.$toast(`${reminderDate } ${doc.data().text}`, {
                  position: "top-left",
                  timeout: false,
                  maxToasts: 1,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false
                });
              }
            }

          });
          this.reminders = reminders;
        });
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
      leads: "leads",
    }),
    lead() {
      return this.leads.lead;
    },
    sortedUsers:function() {
      let users = this.filteredUsers;
      return users.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },

    favourited() {
      if (this.lead) {
        if (
          this.lead.favourited &&
          this.lead.favourited.includes(this.user.data.uid)
        ) {
          return true;
        }
      }
      return false;
    },
    filteredUsers: function () {
      let users = this.users;
      if (this.query !== "") {
        const searchQuery = this.query.toLowerCase();
        return users.filter(
          (item) =>
            (item.firstName &&
              item.firstName.toLowerCase().includes(searchQuery)) ||
            (item.lastName &&
              item.lastName.toLowerCase().includes(searchQuery)) ||
            (item.email && item.email.toLowerCase().includes(searchQuery)) ||
            (item.phone && item.phone.toLowerCase().includes(searchQuery))
        );
      }

      if (this.activeTab === "favourites") {
        users = users.filter(
          (item) =>
            item.favourited && item.favourited.includes(this.user.data.uid)
        );
      }

      return users;
    },
    totalUsers: function () {
      if (!isNil(this.users)) {
        return this.users.length;
      }

      return 0;
    },
  },
  watch: {
    posts () {
      this.setPages();
      this.refreshLead();
    }
  },
  created() {
    this.getUsers();
    this.refreshLead();
    this.getReminders();

    profilesCollection
      .where("role", "==", "agent")
      // .where("role", "==", "agent-principal")
      .get()
      .then((snap) => {
        let agents = [];
        snap.forEach((doc) => {
          agents.push({
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
            email: doc.data().email,
            contact: doc.data().formatNationalContactNumber,
            created: doc.data().created,
            role: doc.data().role,
            order: doc.data().order,
            id: doc.id,
          });
        });
        this.agents = agents;
      });

    setInterval(this.updateNow, 1000);
  },
};
</script>

<style>
.scroll {
  overflow-x: scroll;
  width: 100%;
}
.tab-switch-default {
  font-size: .875rem;
  line-height: 1.25rem;
  padding: .75rem 1.25rem;
  color: rgb(189 190 189);
  overflow: hidden;
  margin-bottom: 1.25rem;
}

.tab-switch {
  @apply mb-5 overflow-hidden;
  @apply text-sm px-5 py-3 text-black;
  outline: none;
  border: 0;
  border-bottom: 5px solid rgba(170, 148, 97, var(--tw-bg-opacity));
}

.o-table__th {
  @apply uppercase py-3;
  border: 0 !important;
}

.o-table__th,
.o-table__td {
  @apply text-xs px-1 py-1;
}

.o-table__td {
  @apply align-middle;
}

.o-table__td a {
  @apply text-primary;
}

.o-pag__link {
  @apply p-1 px-3;
}

.o-pag__link.o-pag__link--current {
  @apply bg-primary font-semibold;
}

.o-table__td select {
  height: 40px;
  line-height: 40px;
  width: 90px;
  padding: 5px;
  @apply text-xs;
}

.rating-warm {
  background: #ffc16e;
  color: white;
}

.rating-cold {
  background: #70a9ff;
  color: white;
}

.rating-tbc {
  background: #3e404c;
  color: white;
}

.rating-sale {
  background: #36ce9d;
  color: white;
}

.rating-hot {
  background: #ff7670;
  color: white;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.sorting {
  background: #ffffff33;
}

.heading-text {
  cursor: pointer;
  text-align: center;
  padding: 0.75rem 1.5rem;
}

.heading-text:hover {
  opacity: .7;
  background: #fffff333;
}

.tableColumnWidth {
  width: 20%;
  word-wrap: break-word
}
</style>
