<template>
  <div
    class="fixed bg-white h-full profile-slide-in overflow-hidden"
    :class="{ 'show-profile': show }"
    style="border-top-left-radius: 0.75rem"

  >
    <div
      class="p-5 relative"
      :class="getRatingClass(lead.rating)"
      style="border-top-left-radius: 0.75rem"
    >
      <a
        class="absolute cursor-pointer"
        @click="$emit('close')"
        style="top: 5px; right: 5px"
        ><CloseIcon
      /></a>
      <span class="uppercase block text-xs">{{ lead.rating }} Lead</span>
      <span class="text-lg font-semibold block"
        >{{ lead.firstName }} {{ lead.lastName }}</span
      >
    </div>
    <div class="text-xs p-5">
      <div class="detail-tabs">
        <button
          @click="activeTab = 'details'"
          :class="{ active: activeTab === 'details' }"
        >
          Details
        </button>
        <button
          @click="activeTab = 'notes'"
          :class="{ active: activeTab === 'notes' }"
        >
          Notes
        </button>
        <button
          @click="activeTab = 'reminders'"
          :class="{ active: activeTab === 'reminders' }"
        >
          Reminders ({{ reminders.length }})
        </button>
      </div>
      <ul class="lead-detail-fields" v-if="activeTab === 'details'">
        <li class="mb-2" title="Email">
          <img
            src="/leadicons/mail_outline_black_24dp.svg"
            class="inline-block"
          />
          <a :href="`mailto:${lead.email}`" class="text-xs inline-block">{{
            lead.email
          }}</a>
        </li>
        <!--agent-->
        <li class="mb-2" title="Agent">           
          <div v-for="agent in allAgents" :key="agent.profileID">
            <div v-if="agent.profileID === lead.agentId" class="flex align-items">
                <AgentsIcon :size="17" />
              <div style="padding-left:7px">
                {{agent.firstName}} {{agent.lastName}}
              </div>
            </div>
          </div>
        </li>
        <li class="mb-2" title="Phone">
          <img src="/leadicons/call_black_24dp.svg" class="inline-block" />
          <a :href="`tel:${lead.phone}`" class="text-xs inline-block">{{
            lead.phone
          }}</a>
        </li>
        <li class="mb-2" title="Added">
          <img
            src="/leadicons/event_available_black_24dp.svg"
            class="inline-block"
          />
          {{ lead.created.toDate() | moment("DD/MM/YYYY HH:mm") }}
        </li>
        <li class="mb-2" title="Location">
          <img src="/leadicons/place_black_24dp.svg" class="inline-block" />
          {{ lead.location }}
        </li>
        <li class="mb-2" title="Source">
          <img src="/leadicons/radar_black_24dp.svg" class="inline-block" />
          {{ lead.source }}
        </li>
        <li class="mb-2" title="Interested In">
          <img src="/leadicons/house_black_24dp.svg" class="inline-block" />
          {{ lead.interestedin }}
        </li>
        <li class="mb-2" title="Shortlist">
          <img src="/leadicons/star_rate_black_24dp.svg" class="inline-block" />
          {{ shortlist.join(", ") }}
        </li>
        <li class="mb-2" title="Rating">
          <img
            src="/leadicons/thermostat_black_24dp.svg"
            class="inline-block"
          />
          <span class="capitalize">{{ lead.rating }}</span> Lead
        </li>
        <li>
          <div class="grid grid-cols-7">
            <div class="text-xs col-span-7">
              <img
                src="/leadicons/forum_black_24dp.svg"
                class="inline-block"
                style="margin-right: 0.4rem"
              />

              Correspondence
              <a
                href=""
                @click.prevent="$store.commit('SHOW_CONTACT_MODAL', true)"
                class="relative"
                style="top: -2px"
              >
                <img
                  src="/leadicons/add_circle_outline_green_24dp.svg"
                  class="inline-block"
              /></a>
            </div>
            <div
              class="text-xs col-span-3 ml-6"
              v-if="
                lead.contacthistory &&
                Object.keys(lead.contacthistory).length > 0
              "
            >
              Attempted<br />
              <div
                class="pt-1"
                v-if="
                  lead.contacthistory['call'] &&
                  lead.contacthistory['call'].contacted
                "
              >
                <a
                  href=""
                  @click.prevent="
                    $store.commit('SET_CONTACT_METHOD', 'call');
                    $store.commit('SHOW_CONTACT_MODAL', true);
                  "
                  ><img
                    src="/leadicons/call_black_24dp.svg"
                    class="inline-block"
                /></a>
                <span> Yes</span>
              </div>
              <div
                v-if="
                  lead.contacthistory['email'] &&
                  lead.contacthistory['email'].contacted
                "
              >
                <a
                  href=""
                  @click.prevent="
                    $store.commit('SET_CONTACT_METHOD', 'email');
                    $store.commit('SHOW_CONTACT_MODAL', true);
                  "
                  ><img
                    src="/leadicons/mail_outline_black_24dp.svg"
                    class="inline-block"
                /></a>
                <span>Yes</span>
              </div>
              <div
                v-if="
                  lead.contacthistory['whatsapp'] &&
                  lead.contacthistory['whatsapp'].contacted
                "
              >
                <a
                  href=""
                  @click.prevent="
                    $store.commit('SET_CONTACT_METHOD', 'whatsapp');
                    $store.commit('SHOW_CONTACT_MODAL', true);
                  "
                  ><img
                    src="/leadicons/whatsapp_black_24dp.svg"
                    class="inline-block"
                /></a>
                <span>Yes</span>
              </div>
            </div>
            <div
              class="text-xs col-span-3"
              v-if="
                lead.contacthistory &&
                Object.keys(lead.contacthistory).length > 0
              "
            >
              Successful
              <div
                class="relative"
                style="top: 4px"
                v-if="
                  lead.contacthistory['call'] &&
                  lead.contacthistory['call'].contacted
                "
              >
                {{
                  lead.contacthistory["call"] &&
                  lead.contacthistory["call"].contacted &&
                  lead.contacthistory["call"].successfulContact
                    ? "Yes"
                    : "No"
                }}
              </div>
              <div
                class="relative"
                style="top: 4px"
                v-if="
                  lead.contacthistory['email'] &&
                  lead.contacthistory['email'].contacted
                "
              >
                {{
                  lead.contacthistory &&
                  lead.contacthistory["email"] &&
                  lead.contacthistory["email"].contacted &&
                  lead.contacthistory["email"].successfulContact
                    ? "Yes"
                    : "No"
                }}
              </div>
              <div
                class="relative"
                style="top: 3px"
                v-if="
                  lead.contacthistory['whatsapp'] &&
                  lead.contacthistory['whatsapp'].contacted
                "
              >
                {{
                  lead.contacthistory &&
                  lead.contacthistory["whatsapp"] &&
                  lead.contacthistory["whatsapp"].contacted &&
                  lead.contacthistory["whatsapp"].successfulContact
                    ? "Yes"
                    : "No"
                }}
              </div>
            </div>
          </div>
        </li>
        <li class="mb-2 mt-4">
          <img
            src="/leadicons/speaker_notes_black_24dp.svg"
            class="inline-block"
          />
          <span class="text-xs col-span-6"
            >Notes
            <a
              href=""
              @click.prevent="$store.commit('SHOW_NOTES_MODAL', true)"
              class="relative"
              style="top: -2px"
            >
              <img
                src="/leadicons/add_circle_outline_green_24dp.svg"
                class="inline-block" /></a
          ></span>
          <div v-if="!lead.note">No notes yet.</div>
          <div v-else>
            <span class="capitalize">{{
              lead.note.date.toDate() | moment("DD/MM/YYYY HH:mm")
            }}</span>
            <span v-if="lead.note.createdbyfirstname">
              - {{ lead.note.createdbyfirstname }}
              {{ lead.note.createdbylastname.substring(0, 1) }}</span
            ><br />
            <span class="ml-8 block">{{ lead.note.text }}</span>
          </div>
          <br />
        </li>
        <li class="mb-2">
          <img
            src="/leadicons/notifications_active_black_24dp.svg"
            class="inline-block"
          />
          <span class="text-xs col-span-6"
            >Reminders
            <a
              href=""
              @click.prevent="$store.commit('SHOW_REMINDER_MODAL', true)"
              class="relative"
              style="top: -2px"
            >
              <img
                src="/leadicons/add_circle_outline_green_24dp.svg"
                class="inline-block" /></a
          ></span>
          <div v-if="!reminders || (reminders && reminders.length === 0)">
            No reminders
          </div>
          <ul v-if="reminders.length > 0">
            <li
              v-for="(reminder, index) in reminders"
              class="mb-2 pb-2"
              style="border-bottom: 1px solid #ccc"
              :key="index"
            >
              <span class="capitalize">{{
                reminder.date.toDate() | moment("DD/MM/YYYY HH:mm")
              }}</span
              ><br />
              <span class="block">{{ reminder.text }}</span>
              <a
                href=""
                class="text-primary"
                @click.prevent="markComplete(reminder)"
                v-if="!reminder.completed"
                >Mark Complete</a
              >
              <span class="uppercase" v-else>Completed</span>
            </li>
          </ul>
        </li>
        <!-- <li class="mb-2" style="font-size: 70%">
          <img src="/leadicons/person_black_24dp.svg" class="inline-block" />
          <span class="mr-1" :class="{ 'font-semibold': lead.userId }"
            >Signed Up</span
          ><span class="mr-1">Enquired</span
          ><span class="mr-1">Pending Unit</span
          ><span class="mr-1">Reserved Unit</span>
        </li> -->
      </ul>
      <div v-if="activeTab === 'notes'">
        <button
          class="p-1 px-4 bg-black rounded-xl text-white"
          @click="$store.commit('SHOW_NOTES_MODAL', true)"
        >
          New
        </button>
        <div class="mt-1 mb-3">
          <span v-if="notes.length === 0">No notes yet.</span>
          <div class="overflow-auto" style="height: 450px">
            <ul v-if="notes.length > 0">
              <li
                v-for="(note, index) in notes"
                class="mb-2 pb-2"
                style="border-bottom: 1px solid #ccc"
                :key="index"
              >
                <span class="capitalize"
                  >{{ note.date.toDate() | moment("DD/MM/YYYY HH:mm") }}
                  <span v-if="note.name !== ' '"> - {{ note.name }}</span></span
                ><br />
                <span class="block">{{ note.text }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-if="activeTab === 'reminders'">
        <button
          class="p-1 px-4 bg-black rounded-xl text-white"
          @click="$store.commit('SHOW_REMINDER_MODAL', true)"
         >
          New
        </button>
        <li class="mb-2">
          <div v-if="!reminders || (reminders && reminders.length === 0)">
            No reminders
          </div>
          <ul v-if="reminders.length > 0">
            <li
              v-for="(reminder, index) in reminders"
              class="mb-2 pb-2"
              style="border-bottom: 1px solid #ccc"
              :key="index"
            >
              <span class="capitalize">{{
                reminder.date.toDate() | moment("DD/MM/YYYY HH:mm")
              }}</span
              ><br />
              <span class="block">{{ reminder.text }}</span>
              <a
                href=""
                class="text-primary"
                @click.prevent="markComplete(reminder)"
                v-if="!reminder.completed"
                >Mark Complete</a
              >
              <span class="uppercase" v-else>Completed</span>
            </li>
          </ul>
        </li>
      </div>

      <div class="text-center mt-5">
        <div class="mb-3" v-if="hasAccess('leads', 'edit')">
          <router-link
            class="uppercase rounded-3xl text-white bg-primary p-2 pl-6 pr-6"
            :to="{ name: 'admin-lead', params: { id: lead.id } }"
            ><img
              src="/leadicons/edit_white_24dp.svg"
              class="inline-block mr-2 w-4"
            />Edit Lead</router-link
          >
        </div>
        <div>
          <a
            class="uppercase cursor-pointer"
            @click="$emit('mark-favourite', lead.id)"
            >{{ favourited ? "Unmark" : "Mark" }} Favourite</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "vue-material-design-icons/Close.vue";
import firebase from "firebase";
import {
  profilesCollection,
  leadsCollection,
  leadNotesCollection,
  leadRemindersCollection,
} from "../../firebase.js";
import { mapGetters } from "vuex";
import permissionsMixin from "@/mixins/permissions";
import AgentsIcon from "vue-material-design-icons/FaceAgent.vue";

export default {
  name: "LeadDetail",
  mixins: [permissionsMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    favourited: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CloseIcon,
    AgentsIcon
  },
  data() {
    return {
      allAgents: [],
      shortlist: [],
      notes: [],
      reminders: [],
      activeTab: "details",
    };
  },
  watch: {
    lead(newValue) {
      if (newValue.userId) {
        this.shortlist = [];
        profilesCollection
          .doc(newValue.userId)
          .get()
          .then((doc) => {
            if (
              doc.data().unitShortlist &&
              doc.data().unitShortlist.length > 0
            ) {
              this.shortlist = doc
                .data()
                .unitShortlist.map((item) => item.unitName);
            }
          });
      }

      leadNotesCollection
        .where("leadId", "==", newValue.id)
        .orderBy("date", "desc")
        .onSnapshot((snap) => {
          let notes = [];
          snap.forEach((doc) => {
            notes.push({
              date: doc.data().date,
              text: doc.data().text,
              name:
                (doc.data().createdbyfirstname || "") +
                " " +
                (doc.data().createdbylastname?.substring(0, 1) || ""),
            });
          });
          this.notes = notes;
        });

      leadRemindersCollection
        .where("leadId", "==", newValue.id)
        .orderBy("date", "desc")
        .onSnapshot((snap) => {
          let reminders = [];
          snap.forEach((doc) => {
            reminders.push({
              id: doc.id,
              date: doc.data().date,
              text: doc.data().text,
              completed: doc.data().completed || false,
              triggered: doc.data().triggered || false,
            });
          });
          this.reminders = reminders.filter((item) => !item.triggered);
        });
    },
  },
  methods: {

    markComplete(reminder) {
      leadRemindersCollection
        .doc(reminder.id)
        .update({
          completed: true,
          completeddate: firebase.firestore.Timestamp.now(),
        })
        .then(() => {
          if (this.reminders.length > 0) {
            let found = this.reminders.find((item) => !item.completed);
            leadsCollection.doc(this.lead.id).update({
              reminderDate: found.date,
            });
          } else {
            leadsCollection.doc(this.lead.id).update({
              reminderDate: null,
            });
          }
        });
    },
    getRatingClass(rating) {
      return `rating-${rating.toLowerCase()}`;
    },
  },
  computed: {
    ...mapGetters(["leads"]),
    lead() {
      return this.leads.lead;
    },
  },
  mounted() {
    this.lead();   
  },
  created() {
    profilesCollection
      .where("role", "==", "agent")
      .get()
      .then((snap) => {
        let allAgents = [];
        snap.forEach((doc) => {
          allAgents.push({
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
            profileID: doc.id,
          });
                
        });
        this.allAgents = allAgents;
        console.log(this.allAgents);
        this.getMyAgent();
      });
  },
};
</script>


<style>
.profile-slide-in {
  overflow-y: scroll;
  transform: translateX(500px);
  transition: all 0.3s ease-in-out;
  right: 5px;
  top: 0;
  bottom: 0;
  background: white;
  width: 300px;
  opacity: 0;
}

.profile-slide-in.show-profile {
  transform: translateX(0);
  opacity: 1;
}

.lead-detail-fields li img {
  @apply mr-2;
  width: 16px;
}

.detail-tabs {
  @apply mb-3;
}

.detail-tabs button {
  @apply px-2 py-1 font-semibold uppercase;
}

 .detail-tabs button.active {
  background-color: #f3f4f6;
} 
</style>

