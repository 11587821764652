<template>
  <div>
    <button
      class="lb-dialog-close-btn-x"
      v-if="show"
      v-on:click="$store.commit('SHOW_REMINDER_MODAL', false)"
    >
      ×
    </button>

    <div
      v-if="show"
      class="fixed z-50 top-16 left-1/2 transform -translate-x-1/2 w-full px-5 lg:px-0 lg:max-w-3xl overflow-x-hidden overflow-y-auto p-1 inset-0 outline-none focus:outline-none"
    >
      <div class="relative w-auto mx-auto bg-white rounded-lg shadow-lg">
        <!--content-->
        <div
          class="border-0 relative flex flex-col w-full outline-none focus:outline-none"
        >
          <div class="relative flex-auto p-6">
            <div class="flex flex-wrap">
              <div class="w-full">
                <div class="px-4 py-5 flex-auto">
                  <h1 class="text-lg font-semibold">Add new reminder</h1>
                  <div class="grid grid-cols-2">
                    <div class="w-full mt-5 pr-6">
                      <label class="w-full block">Date</label>
                      <o-datetimepicker
                        v-model="reminderDate"
                        placeholder="Click to select..."
                        class="w-full rounded"
                        override
                      >
                        <div
                          class="buttons-footer w-full flex justify-between mb-2 px-2"
                        >
                          <o-button
                            class="bg-primary px-4 py-2 text-white font-semibold rounded"
                            @click="reminderDate = new Date()"
                          >
                            <o-icon icon="calendar"></o-icon>
                            <span>Today</span>
                          </o-button>
                          <o-button
                            class="bg-primary px-4 py-2 text-white font-semibold rounded"
                            @click="reminderDate = null"
                          >
                            <o-icon icon="times"></o-icon>
                            <span>Clear</span>
                          </o-button>
                        </div>
                      </o-datetimepicker>
                    </div>
                    <div class="w-full mt-5 pr-6">
                      <label class="w-full block mb-1">Add Note</label>
                      <textarea
                        class="lb-admin-form-input rounded"
                        v-model="reminderNote"
                      />
                    </div>
                  </div>
                  <button
                    class="mt-3 bg-primary px-4 py-2 text-white font-semibold rounded"
                    @click="addNote"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="show"
      @click="$store.commit('SHOW_CONTACT_MODAL', false)"
      class="opacity-25 fixed inset-0 z-40 bg-black"
    ></div>
  </div>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import { leadRemindersCollection, leadsCollection } from "../../firebase.js";

export default {
  name: "AddReminderModal",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      reminderDate: new Date(),
      reminderNote: "",
    };
  },
  methods: {
    addNote() {
      leadRemindersCollection
        .add({
          created: firebase.firestore.Timestamp.now(),
          createdby: this.user.data.uid,
          createdbyfirstname: this.user.profile.firstName,
          createdbylastname: this.user.profile.lastName,
          leadId: this.lead.id,
          date: this.reminderDate,
          text: this.reminderNote,
          email: this.user.data.email,
        })
        .then(() => {
          if (
            !this.lead.reminderDate ||
            this.lead.reminderDate > this.reminderDate
          )
            leadsCollection.doc(this.lead.id).update({
              reminderDate: this.reminderDate,
            });
          this.$store.commit("SHOW_REMINDER_MODAL", false);
          this.$emit("update-lead");
        });
    },
  },
  watch: {
    show() {
      this.reminderDate = new Date();
      this.reminderNote = "";
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
      leads: "leads",
    }),
    lead() {
      return this.leads.lead;
    },
  },
};
</script>