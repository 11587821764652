<template>
  <div>
    <button
      class="lb-dialog-close-btn-x"
      v-if="show"
      v-on:click="$store.commit('SHOW_NOTES_MODAL', false)"
    >
      ×
    </button>

    <div
      v-if="show"
      class="fixed z-50 top-16 left-1/2 transform -translate-x-1/2 w-full px-5 lg:px-0 lg:max-w-3xl overflow-x-hidden overflow-y-auto p-1 inset-0 outline-none focus:outline-none"
    >
      <div class="relative w-auto mx-auto bg-white rounded-lg shadow-lg">
        <!--content-->
        <div
          class="border-0 relative flex flex-col w-full outline-none focus:outline-none"
        >
          <div class="relative flex-auto p-6">
            <div class="flex flex-wrap">
              <div class="w-full">
                <div class="px-4 py-5 flex-auto">
                  <h1 class="text-lg font-semibold">Add new note</h1>
                  <div class="w-full lg:w-1/3 mt-5 pr-6">
                    <label class="w-full">Date</label>
                    <o-datetimepicker
                      v-model="note.date"
                      placeholder="Click to select..."
                      class="w-full rounded"
                      override
                    >
                      <div
                        class="buttons-footer w-full flex justify-between mb-2 px-2"
                      >
                        <o-button
                          class="bg-primary px-4 py-2 text-white font-semibold rounded"
                          @click="note.date = new Date()"
                        >
                          <o-icon icon="calendar"></o-icon>
                          <span>Today</span>
                        </o-button>
                        <o-button
                          class="bg-primary px-4 py-2 text-white font-semibold rounded"
                          @click="note.date = null"
                        >
                          <o-icon icon="times"></o-icon>
                          <span>Clear</span>
                        </o-button>
                      </div>
                    </o-datetimepicker>
                  </div>
                  <div class="w-full lg:w-1/3 mt-5 pr-6">
                    <label class="w-full">Content</label>
                    <textarea
                      class="lb-admin-form-input rounded"
                      v-model="note.text"
                    />
                  </div>
                  <button
                    class="mt-3 bg-primary px-4 py-2 text-white font-semibold rounded"
                    @click="addNote"
                  >
                    Add Note
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="show"
      @click="$store.commit('SHOW_NOTES_MODAL', false)"
      class="opacity-25 fixed inset-0 z-40 bg-black"
    ></div>
  </div>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import { leadsCollection, leadNotesCollection } from "../../firebase.js";

export default {
  name: "AddNotesModal",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      note: {
        date: new Date(),
      },
    };
  },
  watch: {
    show() {
      this.note.date = new Date();
      this.note.text = "";
    },
  },
  methods: {
    addNote() {
      this.note.created = firebase.firestore.Timestamp.now();
      this.note.createdby = this.user.data.uid;
      this.note.createdbyfirstname = this.user.profile.firstName;
      this.note.createdbylastname = this.user.profile.lastName;
      this.note.leadId = this.lead.id;
      leadNotesCollection
        .add(this.note)
        .then(() => {
          leadsCollection.doc(this.lead.id).update({
            note: {
              date: this.note.date,
              text: this.note.text,
              createdbyfirstname: this.user.profile.firstName,
              createdbylastname: this.user.profile.lastName,
            },
          });
        })
        .then(() => {
          this.$store.commit("SHOW_NOTES_MODAL", false);
        });
    },
  },
  created() {},
  computed: {
    ...mapGetters({
      user: "user",
      leads: "leads",
    }),
    lead() {
      return this.leads.lead;
    },
  },
};
</script>