var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-2"},[_c('h2',{staticClass:"my-4 text-4xl font-semibold"},[_vm._v("Leads")]),_c('div',{staticClass:"pb-2 items-center justify-between text-gray-600"},[_c('div',{staticClass:"relative"},[_c('span',[_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.totalUsers)+" ")]),_vm._v(" leads ")]),(_vm.hasAccess('leads', 'add'))?_c('router-link',{staticClass:"rounded-3xl bg-green-400 hover:bg-green-500 text-xs text-white font-semibold p-2 px-5 absolute -top-2",staticStyle:{"right":"150px"},attrs:{"to":{ name: 'admin-lead' }}},[_vm._v(" Add Lead ")]):_vm._e(),(_vm.hasAccess('leads', 'import'))?_c('router-link',{staticClass:"rounded-3xl bg-green-400 hover:bg-green-500 text-xs text-white font-semibold p-2 px-5 absolute right-3 -top-2",attrs:{"to":{ name: 'admin-import-leads' }}},[_vm._v(" Import Leads ")]):_vm._e()],1)]),_c('div',{staticClass:"p-5 pt-1 rounded-xl relative overflow-hidden",staticStyle:{"min-height":"700px"}},[_c('ul',{staticClass:"flex flex-row flex-wrap cursor-pointer"},[_c('a',{staticClass:"tab-switch-default text-xs font-bold py-3 leading-normal",class:{
          'text-black': _vm.activeTab !== 'all',
          'tab-switch ': _vm.activeTab === 'all',
        },on:{"click":function($event){return _vm.toggleTabs('all')}}},[_c('i',{staticClass:"text-base"}),_vm._v(" All ")]),_c('a',{staticClass:"tab-switch-default text-xs font-bold py-3 leading-normal",class:{
          'text-black': _vm.activeTab !== 'favourites',
          'tab-switch ': _vm.activeTab === 'favourites',
        },on:{"click":function($event){return _vm.toggleTabs('favourites')}}},[_c('i',{staticClass:"text-base"}),_vm._v(" Favourites ")])]),_c('div',{class:{
          hidden: _vm.activeTab !== 'all',
          block: _vm.activeTab === 'all',
        }},[_c('LeadTable')],1),_c('div',{class:{
        hidden: _vm.activeTab !== 'favourites',
        block: _vm.activeTab === 'favourites',
      }},[_c('LeadTableFavourites')],1)]),_c('AddNotesModal',{attrs:{"show":_vm.leads.showNotesModal}}),_c('AddContactModal',{attrs:{"show":_vm.leads.showContactModal,"contact-method":_vm.leads.contactMethod},on:{"update-lead":_vm.refreshLead}}),_c('AddReminderModal',{attrs:{"show":_vm.leads.showReminderModal},on:{"update-lead":_vm.refreshLead}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }