<template>
  <!-- Page Content -->
  <div class="my-2">
    <h2 class="my-4 text-4xl font-semibold">Leads</h2>
    <div class="pb-2 items-center justify-between text-gray-600">
      <div class="relative">
        <span>
          <span class="text-primary"> {{ totalUsers }} </span>
          leads
        </span>
        <router-link
          :to="{ name: 'admin-lead' }"
          v-if="hasAccess('leads', 'add')"
          class="rounded-3xl bg-green-400 hover:bg-green-500 text-xs text-white font-semibold p-2 px-5 absolute -top-2"
          style="right: 150px"
        >
          Add Lead
        </router-link>
        <router-link
          :to="{ name: 'admin-import-leads' }"
          v-if="hasAccess('leads', 'import')"
          class="rounded-3xl bg-green-400 hover:bg-green-500 text-xs text-white font-semibold p-2 px-5 absolute right-3 -top-2"
        >
          Import Leads
        </router-link>
      </div>
    </div>
    <!--    &lt;!&ndash; Search Content &ndash;&gt;-->
    <!--    <input-->
    <!--      type="search"-->
    <!--      v-model="query"-->
    <!--      placeholder="Search..."-->
    <!--      class="w-full p-2 mb-3 mt-3"-->
    <!--    />-->

    <!-- Toggle Switch Content -->
    <div
      class="p-5 pt-1 rounded-xl relative overflow-hidden"
      style="min-height: 700px"
    >
      <ul class="flex flex-row flex-wrap cursor-pointer">
        <a
          class="tab-switch-default text-xs font-bold py-3 leading-normal"
          v-on:click="toggleTabs('all')"
          v-bind:class="{
            'text-black': activeTab !== 'all',
            'tab-switch ': activeTab === 'all',
          }"
        >
          <i class="text-base"></i>
          All
        </a>

        <a
          class="tab-switch-default text-xs font-bold py-3 leading-normal"
          v-on:click="toggleTabs('favourites')"
          v-bind:class="{
            'text-black': activeTab !== 'favourites',
            'tab-switch ': activeTab === 'favourites',
          }"
        >
          <i class="text-base"></i>
          Favourites
        </a>
      </ul>
      <!-- Leads All Content -->
      <div
        v-bind:class="{
            hidden: activeTab !== 'all',
            block: activeTab === 'all',
          }"
      >
        <!-- Leads All Table Content -->
        <LeadTable />
      </div>
      <div
        v-bind:class="{
          hidden: activeTab !== 'favourites',
          block: activeTab === 'favourites',
        }"
      >
        <!-- Leads Favourites Table Content -->
        <LeadTableFavourites />
      </div>
    </div>

    <!-- Leads Favourites Sidebar Menu Detail Content -->
    <AddNotesModal :show="leads.showNotesModal" />
    <AddContactModal
      :show="leads.showContactModal"
      :contact-method="leads.contactMethod"
      @update-lead="refreshLead"
    />
    <AddReminderModal
      :show="leads.showReminderModal"
      @update-lead="refreshLead"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
import {leadsCollection, profilesCollection} from "../../firebase.js";
import isNil from "lodash/isNil";
import "@mdi/font/css/materialdesignicons.min.css";
import AddNotesModal from "@/components/admin/AddNotesModal.vue";
import AddContactModal from "@/components/admin/AddContactModal.vue";
import AddReminderModal from "@/components/admin/AddReminderModal.vue";
import permissionsMixin from "@/mixins/permissions";
import LeadTable from "@/components/admin/LeadTable.vue";
import LeadTableFavourites from "@/components/admin/LeadTableFavourites.vue";
import {APP_ADMIN_PAGINATION} from "@/Constants";

export default {
  name: "AdminLeadsList",
  mixins: [permissionsMixin],
  components: {
    LeadTable,
    LeadTableFavourites,
    AddNotesModal,
    AddContactModal,
    AddReminderModal,
  },
  data: () => ({
    currentSort:'created',
    currentSortDir:'desc',
    pageSize: APP_ADMIN_PAGINATION,
    currentPage:1,
    users: [],
    agents: [],

    loading: true,
    query: "",
    isAdmin: false,
    showProfile: true,
    activeTab: "all",
    now: new Date(),
  }),
  methods: {
    isSorted(field, direction) {
      if (this.currentSort === field && this.currentSortDir === direction) {
        return true;
      }
      return false;
    },
    sort:function(s) {
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='desc'?'asc':'desc';
      }
      this.currentSort = s;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredUsers.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    toggleTabs: function (tabValue) {
      this.activeTab = tabValue;
    },
    showDetail(lead) {
      this.showProfile = true;
      this.$store.commit("SET_LEAD", lead);
    },
    refreshLead() {
      leadsCollection
        .doc(this.lead.id)
        .get()
        .then((doc) => this.$store.commit("SET_LEAD", this.setUser(doc)));
    },
    getAgent(id) {
      if (this.agents.length > 0) {
        let agent = this.agents.find((item) => item.id === id);
        return `${agent.firstName} ${agent.lastName}`;
      }
      return "";
    },
    markFavourite(leadId) {
      if (this.favourited) {
        this.lead.favourited = this.lead.favourited.filter(
          (item) => item !== this.user.data.uid
        );
        leadsCollection.doc(leadId).update({
          favourited: firebase.firestore.FieldValue.arrayRemove(
            this.user.data.uid
          ),
        });
      } else {
        if (!this.lead.favourited) {
          this.lead.favourited = [];
        }
        this.lead.favourited.push(this.user.data.uid);
        leadsCollection.doc(leadId).update({
          favourited: firebase.firestore.FieldValue.arrayUnion(
            this.user.data.uid
          ),
        });
      }
    },
    getRatingClass(rating) {
      if (rating) {
        return `rating-${rating.toLowerCase()}`;
      }
    },
    setUser(doc) {
      return {
        id: doc.id,
        firstName: doc.data().firstName,
        lastName: doc.data().lastName,
        email: doc.data().email,
        phone: doc.data().phone,
        rating: doc.data().rating,
        agentId: doc.data().agentId,
        created: doc.data().created,
        modified: doc.data().modified,
        location: doc.data().location,
        source: doc.data().source,
        interestedin: doc.data().interestedin,
        userId: doc.data().userId,
        favourited: doc.data().favourited,
        note: doc.data().note,
        contacthistory: doc.data().contacthistory || null,
        reminderDate: doc.data().reminderDate || null,
      };

    },
    setUsers(snapshot) {
      let users = [];
      snapshot.forEach((doc) => {
        users.push(this.setUser(doc));
      });
      users.sort((a, b) => {
        return b.created.seconds - a.created.seconds;
      });
      this.users = users;
      this.loading = false;
    },
    getUsers() {
      profilesCollection
        .where("email", "==", this.user.data.email)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            const role = doc.data().role;
            const adminRoles = ["admin", "agent-principal", "superadmin"];
            this.isAdmin = adminRoles.includes(role);
            if (this.isAdmin) {
              leadsCollection.onSnapshot((snap) => {
                this.setUsers(snap);
              });
            } else {
              leadsCollection
                .where("agentId", "==", this.user.data.uid)
                .onSnapshot((snap) => {
                  this.setUsers(snap);
                });
            }
          });
        });
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
      leads: "leads",
    }),
    lead() {
      return this.leads.lead;
    },
    sortedUsers:function() {
      let users = this.filteredUsers;
      return users.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },

    favourited() {
      if (this.lead) {
        if (
          this.lead.favourited &&
          this.lead.favourited.includes(this.user.data.uid)
        ) {
          return true;
        }
      }
      return false;
    },
    filteredUsers: function () {
      let users = this.users;
      if (this.query !== "") {
        const searchQuery = this.query.toLowerCase();
        return users.filter(
          (item) =>
            (item.firstName &&
              item.firstName.toLowerCase().includes(searchQuery)) ||
            (item.lastName &&
              item.lastName.toLowerCase().includes(searchQuery)) ||
            (item.email && item.email.toLowerCase().includes(searchQuery)) ||
            (item.phone && item.phone.toLowerCase().includes(searchQuery))
        );
      }

      if (this.activeTab === "favourites") {
        users = users.filter(
          (item) =>
            item.favourited && item.favourited.includes(this.user.data.uid)
        );
      }

      return users;
    },
    totalUsers: function () {
      if (!isNil(this.users)) {
        return this.users.length;
      }

      return 0;
    },
  },
  watch: {
    posts () {
      this.setPages();
    }
  },
  created() {
    profilesCollection
      .where("role", "==", "agent")
      // .where("role", "==", "agent-principal")
      .get()
      .then((snap) => {
        let agents = [];
        snap.forEach((doc) => {
          agents.push({
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
            email: doc.data().email,
            contact: doc.data().formatNationalContactNumber,
            created: doc.data().created,
            role: doc.data().role,
            order: doc.data().order,
            profileID: doc.id,
          });
        });
        this.agents = agents;
        this.getUsers();
      });
    // let agents = [];
    // agentsCollection
    //   .get()
    //   .then((snap) => {
    //     snap.forEach((doc) => {
    //       agents.push({
    //         id: doc.id,
    //         firstName: doc.data().firstName,
    //         lastName: doc.data().lastName,
    //       });
    //     });
    //     this.agents = agents;
    //     this.getUsers();
    //   });
    setInterval(this.updateNow, 1000);
  },
};
</script>

<style>
.tab-switch-default {
  font-size: .875rem;
  line-height: 1.25rem;
  padding: .75rem 1.25rem;
  color: rgb(189 190 189);
  overflow: hidden;
  margin-bottom: 1.25rem;
}

.tab-switch {
  @apply mb-5 overflow-hidden;
  @apply text-sm px-5 py-3 text-black;
  outline: none;
  border: 0;
  border-bottom: 5px solid rgba(170, 148, 97, var(--tw-bg-opacity));
}

.o-table__th {
  @apply uppercase py-3;
  border: 0 !important;
}

.o-table__th,
.o-table__td {
  @apply text-xs px-1 py-1;
}

.o-table__td {
  @apply align-middle;
}

.o-table__td a {
  @apply text-primary;
}

.o-pag__link {
  @apply p-1 px-3;
}

.o-pag__link.o-pag__link--current {
  @apply bg-primary font-semibold;
}

.o-table__td select {
  height: 40px;
  line-height: 40px;
  width: 90px;
  padding: 5px;
  @apply text-xs;
}

.rating-warm {
  background: #ffc16e;
  color: white;
}

.rating-cold {
  background: #70a9ff;
  color: white;
}

.rating-tbc {
  background: #3e404c;
  color: white;
}

.rating-sale {
  background: #36ce9d;
  color: white;
}

.rating-hot {
  background: #ff7670;
  color: white;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.sorting {
  background: #ffffff33;
}

.heading-text {
  cursor: pointer;
  text-align: center;
  padding: 0.75rem 1.5rem;
}

.heading-text:hover {
  opacity: .7;
  background: #fffff333;
}

.tableColumnWidth {
  width: 20%;
  word-wrap: break-word
}
</style>
