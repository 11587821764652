<template>
  <!-- Content -->
  <div class="mx-10 my-2">
    <h2 class="my-4 text-4xl font-semibold">Upgrade</h2>
    <button class="lb-admin-secondary-button-large mt-5" @click="applyUpgrade">
      Do Upgrade
    </button>
  </div>
</template>

<script>
import { db, leadsCollection, profilesCollection } from "@/firebase";

export default {
  name: "LeadsMigration",
  data: () => ({}),
  methods: {
    applyUpgrade() {
      var profilesPromises = [];
      var profileItems = [];
      var profileIds = [];

      profilesCollection
        .where("role", "==", "user")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            profileIds.push(doc.id);
            profileItems.push({
              userId: doc.id,
              rating: "tbc",
              firstName: doc.data().firstName || null,
              lastName: doc.data().lastName || null,
              phone: doc.data().formatNationalContactNumber || null,
              email: doc.data().email || null,
              created: doc.data().created || null,
            });
          });
        })
        .then(() => {
          profileItems.forEach((data) => {
            profilesPromises.push(leadsCollection.doc().set(data));
          });
          let batch = db.batch();
          Promise.all(profilesPromises).then(() => {
            batch.commit().then(() => console.log("Done"));
          });
        });
    },
  },
  created() {},
};
</script>
